import React, { useState } from 'react';
import './home.css';

const Home = () => {
  return (
    <div className="home">
      Dashboard
    </div>
  )
};

export default Home;
