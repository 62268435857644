module.exports = {
    FIREBASE: {
        API_KEY: 'AIzaSyBS69Ff9Qmur-7gFOmP9P8N4Zpk0ITMNFI',
        AUTH_DOMAIN: 'inkbooks-872df.firebaseapp.com',
        DATABASE_URL: 'gs://inkbooks-872df.appspot.com',
        PROJECT_ID: 'inkbooks-872df',
        STORAGE_BUCKET: 'inkbooks-872df.appspot.com',
        MESSAGING_SENDER_ID: '368649373961',
        APP_ID: '1:368649373961:web:63f422dafcabfc17922bd8',
        MEASUREMENT_ID: 'G-J3Z77PQQZB',
        EMAIL: 'firebase@inkbooks.net',
        EMAIL_PWD: '!nkb00k5.n3t'
    },
    SQUARE: {
        SANDBOX: {
            APPLICATION_NAME: 'inkbooks',
            APPLICATION_ID: 'sandbox-sq0idb-jP6MNHK_aUZtUZgYMYc0RA',
            ACCESS_TOKEN: 'EAAAEIin1aEYlzD9xa9QDCP_evawMtmRuAQN7Ooj_i7aAwRSCPTRy90IuaVMhFBS',
            LOCATION_ID: 'L8YSXGA7M0B9X',
            PROCESS_URL: 'http://localhost:4000/process-payment'
        },
        PRODUCTION: {
            APPLICATION_NAME: 'inkbooks',
            APPLICATION_ID: 'sandbox-sq0idb-jP6MNHK_aUZtUZgYMYc0RA',
            ACCESS_TOKEN: 'EAAAEIin1aEYlzD9xa9QDCP_evawMtmRuAQN7Ooj_i7aAwRSCPTRy90IuaVMhFBS',
            LOCATION_ID: 'L8YSXGA7M0B9X',
            PROCESS_URL: 'http://localhost:4000/process-payment'
        }
    }
}