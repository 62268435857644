import React, { useContext } from 'react';
import "./topbar.css";
import {NotificationsNone, Language, Settings} from '@mui/icons-material';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import PersonAdd from '@mui/icons-material/PersonAdd';
import Logout from '@mui/icons-material/Logout';
import { AuthContext } from '../../context/auth';
import { useNavigate } from 'react-router-dom';
import IBAvatar from '../inputs/IBAvatar';
import { APP_SETTINGS_CONSTANTS, ROUTE_CONSTANTS } from '../../constants';

const Topbar = () => {
    const { user, logout } = useContext(AuthContext);
    let navigate = useNavigate();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleProfileClick = () => {
        navigate(ROUTE_CONSTANTS.PROFILE);
    }

    const handleLogout = (e) => {
        logout();
        navigate(ROUTE_CONSTANTS.HOME);
    }
    //prevents topbar from rendering if the user is not authenticated
    if(!user){
        return (<></>);
    }
    return (
        <div className="topbar">
            <div className="topbarWrapper">
                <div className="topLeft">
                    <span className="logo">Inkbooks</span>
                </div>
                <div className="topRight">
                    
                    {/* <div className="topbarIconContainer">
                        <span onClick={handleLogout}>Logout</span>
                    </div> */}
                    {/* <span onClick={handleLogout}>
                        <img src={user.userInfo.avatar} alt="" className="topAvatar" />
                        {user.firstName}
                    </span> */}
                    <Box sx={{ display: 'flex', alignItems: 'center', textAlign: 'center' }}>
                        <div className="topbarIconContainer">
                            <NotificationsNone />
                            <span className="topIconBadge">2</span>
                        </div>
                        <div className="topbarIconContainer">
                            <Language />
                            <span className="topIconBadge">2</span>
                        </div>
                        <div className="topbarIconContainer">
                            <Settings />
                            <span className="topIconBadge">2</span>
                        </div>
                        <Tooltip title="Account settings">
                        <IconButton
                            onClick={handleClick}
                            size="small"
                            sx={{ ml: 2 }}
                            aria-controls={open ? 'account-menu' : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? 'true' : undefined}
                        >
                            <IBAvatar 
                            size={32}
                            cursor='pointer'
                            imgUrl={user.avatar}
                            label={`${user.firstName} ${user.lastName}`}/>
                        </IconButton>
                        </Tooltip>
                    </Box>
                    <Menu
                        anchorEl={anchorEl}
                        id="account-menu"
                        open={open}
                        onClose={handleClose}
                        onClick={handleClose}
                        PaperProps={{
                        elevation: 0,
                        sx: {
                            overflow: 'visible',
                            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                            mt: 1.5,
                            '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                            },
                            '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                            },
                        },
                        }}
                        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                    >
                        <MenuItem onClick={handleProfileClick}>
                            <IBAvatar 
                            size={32}
                            cursor='pointer'
                            imgUrl={user.avatar}
                            label={`${user.firstName} ${user.lastName}`}/> Profile
                        </MenuItem>
                        <MenuItem>
                            <Avatar /> My account
                        </MenuItem>
                        <Divider />
                        <MenuItem>
                            <ListItemIcon>
                                <PersonAdd fontSize="small" />
                            </ListItemIcon>
                            Add another account
                        </MenuItem>
                        <MenuItem>
                            <ListItemIcon>
                                <Settings fontSize="small" />
                            </ListItemIcon>
                            Settings
                        </MenuItem>
                        <MenuItem onClick={handleLogout}>
                            <ListItemIcon>
                                <Logout fontSize="small" />
                            </ListItemIcon>
                            Logout
                        </MenuItem>
                    </Menu>
                </div>
            </div>
        </div>
    )
}

export default Topbar;