import React, { useContext, useEffect, useState } from "react";
import { io } from "socket.io-client";
import { APP_SETTINGS_CONSTANTS } from "../constants";

const SocketContext = React.createContext();

export function useSocket() {
	return useContext(SocketContext);
}

export function SocketProvider({ id, children }) {
	const [socket, setSocket] = useState();

	useEffect(() => {
		const newSocket = io(APP_SETTINGS_CONSTANTS.SOCKET_IO_SERVER_URL, {
			query: { id },
		});
        setSocket(newSocket);

        return () => newSocket.close();
        
	}, [id]);

	return (
		<SocketContext.Provider value={socket}>
			{children}
		</SocketContext.Provider>
	);
}
